const STATUS_NEW = 1;
const STATUS_CANCELED = 2;
const STATUS_COMPLETED = 3;
const STATUS_PAID = 4;

const PAYMENT_SYSTEM_BANK = "bank";
const PAYMENT_SYSTEM_BITSAFE = "bitsafe";

export const statuses = {
    [STATUS_NEW]: "new",
    [STATUS_CANCELED]: "canceled",
    [STATUS_COMPLETED]: "completed",
    [STATUS_PAID]: "paid",
};

export const paymentSystems = {
    [PAYMENT_SYSTEM_BANK]: "Bank transfer",
    [PAYMENT_SYSTEM_BITSAFE]: "Bitsafe",
};
