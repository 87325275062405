<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col class="text-right">
                        <v-btn
                            text
                            :to="{ name: 'admin.withdrawals.bitsafe.index' }"
                            >return</v-btn
                        >
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-title>WithdrawalBankTransfer</v-card-title>
            <v-card-text>
                <list-fields
                    :item="list.withdrawal.items"
                    :fields="list.withdrawal.fields"
                />
            </v-card-text>
            <v-card-title>Payment data</v-card-title>
            <v-card-text>
                <list-fields
                    :item="list.paymentData.items"
                    :fields="list.paymentData.fields"
                />
            </v-card-text>

            <!-- notes -->
            <v-card-text>
                <v-row dense>
                    <v-col sm="12" md="4">
                        <v-text-field
                            label="Notes"
                            v-model="notes"
                        ></v-text-field>
                    </v-col>

                    <v-col sm="12" md="4">
                        <v-btn
                            class="btn-accent mt-4"
                            @click="updateNotes()"
                            text
                        >
                            save notes
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>

            <!-- status -->
            <v-card-text>
                <v-row dense>
                    <v-col sm="12" md="4">
                        <v-select
                            label="Status"
                            :items="statuses"
                            item-text="text"
                            item-value="value"
                            v-model="status"
                            :disabled="status.text === 'canceled'"
                        />
                    </v-col>
                    <v-col sm="12" md="4">
                        <v-btn
                            class="btn-accent mt-4"
                            @click="updateStatus()"
                            text
                            :disabled="status.text === 'canceled'"
                        >
                            update status
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </admin-dashboard-layout>
</template>

<script>
import AdminDashboardLayout from "../../../../layouts/AdminDashboardLayout";
import ListFields from "../../../../components/app/form/ListFields";
import WithdrawalBankTransfer from "@/models/admin/WithdrawalBankTransfer";
import { paymentSystems, statuses } from "@/views/admin/withdrawals/withdrawal";
import { mapMutations } from "vuex";

export default {
    name: "withdrawals-show",
    components: {
        AdminDashboardLayout,
        ListFields,
    },
    data: function () {
        return {
            errors: {},
            status: 2,
            notes: "",
            withdrawal: {},
            list: {
                paymentData: {
                    fields: [{ code: "card_link", label: "Card link" }],
                    items: [],
                },
                withdrawal: {
                    fields: [
                        { label: "User #ID", code: "user_id" },
                        { label: "Amount In", code: "amount_in" },
                        { label: "Commission", code: "commission" },
                        { label: "Amount out", code: "amount_out" },
                        { label: "Status", code: "status" },
                        { label: "Payment System", code: "payment_system" },
                        { label: "Created time", code: "created_at" },
                    ],
                    items: [],
                },
            },
        };
    },
    computed: {
        statuses() {
            const items = [];
            for (const key in statuses) {
                items.push({ text: statuses[key], value: key });
            }
            return items;
        },
    },
    methods: {
        ...mapMutations(["showSnackBar"]),
        async updateStatus() {
            WithdrawalBankTransfer.custom(this.withdrawal, "status")
                .config({ method: "PATCH", data: { status: this.status } })
                .$get()
                .then(() => this.showSnackBar(this.$t("messages.success")))
                .catch(() => this.showSnackBar("Error"));
        },
        async updateNotes() {
            WithdrawalBankTransfer.custom(this.withdrawal, "notes")
                .config({ method: "PATCH", data: { notes: this.notes } })
                .$get()
                .then(() => this.showSnackBar(this.$t("messages.success")))
                .catch(() => this.showSnackBar("Error"));
        },
        setPaymentDataFields(data) {
            const except = [
                "id",
                "user_id",
                "created_at",
                "updated_at",
                "notes",
            ];
            this.list.paymentData.items = this.filterByKeys(data, except);
        },
        setWithdrawalFields(data) {
            const except = [
                "id",
                "payment_data",
                "created_at",
                "updated_at",
                "notes",
            ];
            const withdrawal = this.filterByKeys(data, except);
            withdrawal.status = statuses[withdrawal.status];
            withdrawal.payment_system =
                paymentSystems[withdrawal.payment_system];
            this.list.withdrawal.items = withdrawal;
        },
        filterByKeys(data, keys) {
            return Object.keys(data)
                .filter((key) => !keys.includes(key))
                .reduce((obj, key) => {
                    obj[key] = data[key];
                    return obj;
                }, {});
        },
    },
    async created() {
        this.withdrawal = await WithdrawalBankTransfer.find(
            this.$route.params.id
        );
        this.setWithdrawalFields(this.withdrawal);
        this.setPaymentDataFields(this.withdrawal.payment_data);
        this.status = this.statuses.find(
            (i) => +i.value === +this.withdrawal.status
        );

        this.notes = this.withdrawal.notes;
    },
};
</script>

<style scoped></style>
